@import "mixin";
@import "animate";

$offcanvas-width: 260px !default;
$color-base: #333;
$color-red: #E00012;
$color-yellow: #FFDD28;

@charset "UTF-8";
@include keyframes(animationOpacity){
	0% {
		opacity:0;
	}
	30% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}
body{
	@include animation(animationOpacity 1.2s ease 0.15s);
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "HelveticaNeue",Helvetica,Arial,sans-serif;
  a{
    color: $color-base;
  }
  a:hover{
    color: $color-base;
  }
  a.disable {
    pointer-events: none;
    cursor: default;
  }
}
.nav-sp{
	@include mq('min', 'lg'){
		.logo{
			width: 160px;
			top:15px;
			left:20px;
			position: fixed;
		}
	  .menu-trigger {
	    display: none;
	  }
	  ul {
	    position: fixed;
	    top: 10px;
	    right: 10px;
	    z-index: 1005;
	    margin: 0 0 5px;
	    li {
	      color: $color-base;
				display: inline-block;
	      a {
	        color: $color-base;
	        display: inline-block;
	        text-decoration: none;
		      padding: .8rem .5rem;
					font-size: .9rem;
					&:hover{
						color: #999;
		      }
	      }
	    }
	  }
		.tel{
			display: none;
		}
	}
	@include mq('max', 'lg') {
		.logo{
			// display: none;
			width: 120px;
			top:10px;
			left:0;
			right:0;
			margin: 0 auto;
			position: absolute;
		}
	  .menu-trigger {
	    display: inline-block;
	    width: 24px;
	    height: 19px;
	    vertical-align: middle;
	    cursor: pointer;
	    position: fixed;
	    top: 15px;
	    left: 20px;
	    z-index: 1002;
	    transform: translateX(0);
	    span {
	      display: inline-block;
	      box-sizing: border-box;
	      position: absolute;
	      left: 0;
	      width: 100%;
	      height: 3px;
	      background-color: #000;
	      transition: all .5s;
	      &:nth-of-type(1) {
	        top: 0;
	      }
	      &:nth-of-type(2) {
	        top: 8px;
	      }
	      &:nth-of-type(3) {
	        bottom: 0;
	      }
	    }
	    &.active {
	      transform: translateX(0);
	      z-index: 2000;
	      span {
	        background-color: #707070 !important;
	        &:nth-of-type(1) {
	          transform: translateY(8px) rotate(-45deg);
	        }
	        &:nth-of-type(2) {
	          opacity: 0;
	        }
	        &:nth-of-type(3) {
	          transform: translateY(-8px) rotate(45deg);
	        }
	      }
	    }
	  }
	  ul {
	    width: 260px;
	    height: 100%;
	    position: fixed;
	    top: 0;
	    left: 0;
	    z-index: 1005;
	    transform: translate(-260px);
	    transition: all .5s;
	    overflow-x:hidden !important;
			opacity: 0;
	    margin: 0 0 5px;
	    padding-top: 50px;
	    &.open {
		    background: #000;
	      transform: translateX(0);
	      overflow:hidden;
	      box-sizing:border-box;
				opacity: 1;

		    li {
					opacity: 1;
				}
	    }
	    li {
	      color: #ccc;
				opacity: 0;
	      &:hover{
	        // background: rgba(225,225,225,.3);
	      }
	      a {
	        color: #ccc;
	        display: block;
	        text-decoration: none;
		      padding: 9px 10px 9px 20px;
					&:hover{
						color: #fff;
		      }
	      }
				&.lang{
					display: flex;
					a+a{
			      padding-left: 10px;
						margin-left: 5px;
					}
				}
	    }
	  }
		.tel{
			position: fixed;
			top:5px;
			right:10px;
			color: #000;
			font-size: 28px;
			z-index: 1000;
			width: 40px;
			height: 40px;
			text-align: center;
			// background: #000;
			i{
				color: #000;
			}
		}
	  .overlay {
	    content: "";
	    display: block;
	    width: 0;
	    height: 0;
	    background-color: rgba(0, 0, 0, 0.5);
	    position: fixed;
	    top: 0;
	    left: 0;
	    z-index: 1001;
	    opacity: 0;
	    transition: opacity .5s;
	    &.open {
	      width: 100%;
	      height: 100%;
	      opacity: 1;
	    }
	  }
	}
	&.colorChange{
		@include mq('min', 'lg'){
			ul li a{
				color: $color-base;
			}
		}
		@include mq('max', 'lg'){
			.menu-trigger span{
				background-color: $color-base;
			}
		}
	}
}

footer{
  .copyright{
    font-size: .625rem;
    text-align: center;
    margin-top: 3rem;
    color: #999;
  }
}
.to-page-top {
	@include mq('min', 'xs') {
	  z-index: 1100;
	  position: fixed;
	  right: 0;
	  bottom: 0;
	  display: none;
	  a {
	    text-decoration: none;
	    display: block;
	    text-align: center;
	    background: rgba(255,255,255,.95);
			padding-top: 10px;
			box-shadow: 0 1px 5px rgba(0,0,0,.2);
			width: 50px;
			height: 50px;
			border-radius: 5px 0 0 0;
			color: $color-base;
	  }
	}
	@include mq('max', 'xs') {
		display: none !important;
	}
}

#localnav ul{
  padding-left: 0;
  margin-bottom: 0;
  li{
    font-size: .9rem;
    list-style: none;
    a{
      display: block;
      text-align: center;
      border:1px solid #ccc;
      margin-bottom: 10px;
      padding: 10px;
      &:hover{
        text-decoration: none;
      }
    }
  }
}
#contents{
  #headline{
    // background: #eee;
    height: 20vw;
    h1{
      font-size: 3rem;
			font-weight: bold;
      vertical-align: middle;
			line-height: 1.6;
    }
    @include mq('max', 'lg') {
	    h1{
	      font-size: 2.4rem;
	      padding-top: 10vw;
				span{
					border: 3px solid #212529;
					padding: 8px 16px;
				}
	    }
		}
		@include mq('max', 'md') {
    	height: 24vw;
			h1{
				font-size: 2.2rem;
				padding-top: 12vw;
			}
		}
		@include mq('max', 'sm') {
    	height: 22vw;
			h1{
				font-size: 1.8rem;
				padding-top: 14vw;
			}
		}
    @include mq('min', 'lg') {
	    h1{
	      padding-top: 10vw;
				span{
					border: 5px solid #212529;
					padding: 10px 20px;
				}
	    }
		}
  }
  @include mq('max', 'lg') {
    #breadcrumb{
      padding-bottom:40px;
    }
  }
  h2{
    font-size: 1.5rem;
		line-height: 1.6;
		font-weight: bold;
		@include mq('max', 'sm') {
		  font-size: 1.4rem;
		}
		@include mq('max', 'xs') {
		  font-size: 1.2rem;
		}
		&.lead{
			font-feature-settings : "palt";
		}
  }
  h3{
    line-height: 1.5;
		font-weight: bold;
		font-size: 1.375rem;
  }
  p{
    line-height: 1.8;
  }
	dt, dd, th, td{
    line-height: 1.6;
  }
	dt{
		@include mq('min', 'md') {
			font-size: 1.1rem;
		}
	}
	dd{
		margin-bottom: .4rem;
	}
  li{
    font-size: .9rem;
    line-height: 1.8;
  }
	a{
		text-decoration: underline;
	}
  .color-red{
    color:$color-red;
  }
  .btn a{
    background: #eee;
		display: block;
		text-decoration: none;
    &:hover, &.hover{
      background: $color-base;
      color: #fff;
			text-decoration: none;
    }
  }
  .viewmore{
		@include mq('max', 'md') {
			text-align: center;
		}
		a.btn{
			border:2px solid $color-base;
			background: #fff;
			color: $color-base;
			font-weight: bold;
			padding:.7rem 3rem;
			text-decoration: none;
	    &:hover, &.hover{
				background: $color-base;
				color: #fff;
				text-decoration: none;
	    }
			@include mq('max', 'md') {
				display: block;
			}
	  }
	}
}

.home{
	// background: url('/_uploads/home-bg.jpg') no-repeat center / cover;
	.nav-sp{
	  .logo{
	    display: none;
		}
		.menu-trigger{
			span{
				background: #fff;
			}
		}
		ul{
			li a{
				color: #fff;
			 }
		}
		.tel a i{
			color: #fff;
		}
	}
	#contents-bg{
		background: url('/_uploads/home-bg.jpg') no-repeat center / cover;
		height: 100vh;
		width: 100vw;
		z-index: -1;
		position: fixed;
		top:0;
		left:0;
	}
	#feature{
		img{
			width: 200px;
			margin-bottom: 30px;
			padding:20px 0;
		}
		p{
			font-size: 1.6rem;
			padding-left: 1rem;
			color: #fff;
		}
	}
  #main{
    .section{
      p{
        margin-bottom: 0;
      }
    }
    #menu{
      .menu{
        margin-bottom: 15px;
        font-size: 1.1rem;
				font-weight: bold;
        a{
	        border:1px solid #eee;
          padding: 2rem 10px;
          display: block;
          text-decoration: none;
          transition: .2s;
          border:1px solid #fff;
					color: #fff;
          &:hover{
          	background: rgba(255,255,255,.95);
						color: $color-base;
          }
        }
      }
			@include mq('min', 'lg') {
				display: flex;
				justify-content: space-between;
				.menu{
					width: 19%;
					a{
						padding: 3rem 10px;
					}
				}
			}
    }
  }
}
.home-movie{
	.movie{
		// background: url('/_uploads/home-bg.jpg') no-repeat / cover;
    position: fixed;
    height: 100vh;
    #top-movie {
			overflow: hidden;
    }
	}
  @include mq('max', 'lg') {
		#contents{
			position: relative;
		}
	}
  @include mq('min', 'lg') {
    .movie {
      #top-movie {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      }
    }
		#contents{
			#main{
		    .section{
		      p{
		        margin-bottom: 0;
		      }
		    }
		    #menu{
		      .menu{
		        margin-bottom: 15px;
		        font-size: 1.1rem;
						font-weight: bold;
						z-index: 9;
		        a{
			        border:1px solid #eee;
		          padding: 2rem 10px;
		          display: block;
		          text-decoration: none;
		          transition: .2s;
		          border:1px solid #fff;
							color: #fff;
		          &:hover{
		          	background: rgba(255,255,255,.95);
								color: $color-base;
		          }
		        }
		      }
					@include mq('min', 'lg') {
						display: flex;
						justify-content: space-between;
						.menu{
							width: 19%;
							a{
								padding: 3rem 10px;
							}
						}
					}
		    }
		  }
		}
  }
	#contentinfo{
		position: relative;
	}
}

.about{
	#main{
		h2{
			line-height: 1.6;
			span{
				font-size: 1.1rem;
			}
		}
		dd{
			margin-bottom: .8rem;
		}
		table{
			th, td{
				padding:.3rem .5rem .3rem 0;
			}
		}
	}
}
.skill{
	#main{
		table{
			th, td{
				padding:.2rem .3rem .2rem 0;
			}
		}
	}
}
.recruit{
	#main{
		.section{
			// a.btn{
			//   background: #FD7765;
			//   border:1px solid #FD7765;
			//   color: #fff;
			// 	padding:.8rem 2rem;
			//   &:hover, &.hover{
			//     background: #fff;
			//     color: #FD7765;
			//   }
			// }
		}
		#interview{
			.voice{
				p.lead{
					line-height: 1.6;
					font-weight: bold;
				}
				.profile{
					border-left:1px solid #707070;
					padding-left: 10px;
					font-size: .9rem;
					line-height: 1.6;
					strong{
						font-size: 1rem;
						font-weight: normal;
						span{
							font-size: .8rem;
						}
					}
				}
				h3{
					font-size: 1.1rem;
					line-height: 1.6;
				}
			}
		}
	}
}
.contact{
	form{
	overflow: hidden;
		table{
			width: 100%;
			th, td{
				display: block;
				width:100%;
			}
			th{
				span.required{
					color: #F33;
					font-size: .7rem;
					font-weight: normal;
					margin-left: .4rem;
				}
			}
			td{
				margin-bottom: 1rem;
				input[type="text"], label, textarea{
				  box-sizing: border-box;
					width: 100%;
				}
				label{
					display: block;
				}
				@include mq('min', 'lg') {
					input[type="text"]{
						width: 50%;
					}
				}
			}
		}
		.btn{
			@include mq('max', 'md') {
				width: 100%;
			}
		}
		dl{
			width: 100%;
			dt{
				font-size: 1rem !important;
				margin-bottom: 2px;
				padding-left: 3px;
				span.required{
					color: #F33;
					font-size: .7rem;
					font-weight: normal;
					margin-left: .4rem;
				}
			}
			dd{
				margin-bottom: 1rem;
				padding-left: 3px;
				input[type="text"], input[type="tel"], input[type="email"], label, textarea{
				  box-sizing: border-box;
					width: 100%;
				}
				label{
					display: block;
					margin-bottom:0;
				}
				.error_blank{
					display: block;
					padding:3px 0 10px;
					color: #F33;
					font-size: .9rem;
				}
				@include mq('min', 'lg') {
					input[type="text"], input[type="tel"], input[type="email"]{
						width: 50%;
					}
				}
			}
		}
	}
}
.access{
	.map {
		position: relative;
		width: 100%;
		padding-top: 75%;
	}
	.map iframe {
		position: absolute;
		top: 0;
		right: 0;
		width: 100% !important;
		height: 100% !important;
	}
}
