@charset "UTF-8";
@keyframes animationOpacity {
  0% {
    opacity: 0; }
  30% {
    opacity: 0; }
  100% {
    opacity: 1; } }

body {
  animation: animationOpacity 1.2s ease 0.15s;
  animation-fill-mode: both;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "HelveticaNeue",Helvetica,Arial,sans-serif; }
  body a {
    color: #333; }
  body a:hover {
    color: #333; }
  body a.disable {
    pointer-events: none;
    cursor: default; }

@media screen and (min-width: 62.0625em) {
  .nav-sp .logo {
    width: 160px;
    top: 15px;
    left: 20px;
    position: fixed; }
  .nav-sp .menu-trigger {
    display: none; }
  .nav-sp ul {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 1005;
    margin: 0 0 5px; }
    .nav-sp ul li {
      color: #333;
      display: inline-block; }
      .nav-sp ul li a {
        color: #333;
        display: inline-block;
        text-decoration: none;
        padding: .8rem .5rem;
        font-size: .9rem; }
        .nav-sp ul li a:hover {
          color: #999; }
  .nav-sp .tel {
    display: none; } }

@media screen and (max-width: 62em) {
  .nav-sp .logo {
    width: 120px;
    top: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: absolute; }
  .nav-sp .menu-trigger {
    display: inline-block;
    width: 24px;
    height: 19px;
    vertical-align: middle;
    cursor: pointer;
    position: fixed;
    top: 15px;
    left: 20px;
    z-index: 1002;
    transform: translateX(0); }
    .nav-sp .menu-trigger span {
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: #000;
      transition: all .5s; }
      .nav-sp .menu-trigger span:nth-of-type(1) {
        top: 0; }
      .nav-sp .menu-trigger span:nth-of-type(2) {
        top: 8px; }
      .nav-sp .menu-trigger span:nth-of-type(3) {
        bottom: 0; }
    .nav-sp .menu-trigger.active {
      transform: translateX(0);
      z-index: 2000; }
      .nav-sp .menu-trigger.active span {
        background-color: #707070 !important; }
        .nav-sp .menu-trigger.active span:nth-of-type(1) {
          transform: translateY(8px) rotate(-45deg); }
        .nav-sp .menu-trigger.active span:nth-of-type(2) {
          opacity: 0; }
        .nav-sp .menu-trigger.active span:nth-of-type(3) {
          transform: translateY(-8px) rotate(45deg); }
  .nav-sp ul {
    width: 260px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1005;
    transform: translate(-260px);
    transition: all .5s;
    overflow-x: hidden !important;
    opacity: 0;
    margin: 0 0 5px;
    padding-top: 50px; }
    .nav-sp ul.open {
      background: #000;
      transform: translateX(0);
      overflow: hidden;
      box-sizing: border-box;
      opacity: 1; }
      .nav-sp ul.open li {
        opacity: 1; }
    .nav-sp ul li {
      color: #ccc;
      opacity: 0; }
      .nav-sp ul li a {
        color: #ccc;
        display: block;
        text-decoration: none;
        padding: 9px 10px 9px 20px; }
        .nav-sp ul li a:hover {
          color: #fff; }
      .nav-sp ul li.lang {
        display: flex; }
        .nav-sp ul li.lang a + a {
          padding-left: 10px;
          margin-left: 5px; }
  .nav-sp .tel {
    position: fixed;
    top: 5px;
    right: 10px;
    color: #000;
    font-size: 28px;
    z-index: 1000;
    width: 40px;
    height: 40px;
    text-align: center; }
    .nav-sp .tel i {
      color: #000; }
  .nav-sp .overlay {
    content: "";
    display: block;
    width: 0;
    height: 0;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    opacity: 0;
    transition: opacity .5s; }
    .nav-sp .overlay.open {
      width: 100%;
      height: 100%;
      opacity: 1; } }

@media screen and (min-width: 62.0625em) {
  .nav-sp.colorChange ul li a {
    color: #333; } }

@media screen and (max-width: 62em) {
  .nav-sp.colorChange .menu-trigger span {
    background-color: #333; } }

footer .copyright {
  font-size: .625rem;
  text-align: center;
  margin-top: 3rem;
  color: #999; }

@media screen and (min-width: 30.125em) {
  .to-page-top {
    z-index: 1100;
    position: fixed;
    right: 0;
    bottom: 0;
    display: none; }
    .to-page-top a {
      text-decoration: none;
      display: block;
      text-align: center;
      background: rgba(255, 255, 255, 0.95);
      padding-top: 10px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
      width: 50px;
      height: 50px;
      border-radius: 5px 0 0 0;
      color: #333; } }

@media screen and (max-width: 30.0625em) {
  .to-page-top {
    display: none !important; } }

#localnav ul {
  padding-left: 0;
  margin-bottom: 0; }
  #localnav ul li {
    font-size: .9rem;
    list-style: none; }
    #localnav ul li a {
      display: block;
      text-align: center;
      border: 1px solid #ccc;
      margin-bottom: 10px;
      padding: 10px; }
      #localnav ul li a:hover {
        text-decoration: none; }

#contents #headline {
  height: 20vw; }
  #contents #headline h1 {
    font-size: 3rem;
    font-weight: bold;
    vertical-align: middle;
    line-height: 1.6; }
  @media screen and (max-width: 62em) {
    #contents #headline h1 {
      font-size: 2.4rem;
      padding-top: 10vw; }
      #contents #headline h1 span {
        border: 3px solid #212529;
        padding: 8px 16px; } }
  @media screen and (max-width: 48em) {
    #contents #headline {
      height: 24vw; }
      #contents #headline h1 {
        font-size: 2.2rem;
        padding-top: 12vw; } }
  @media screen and (max-width: 36em) {
    #contents #headline {
      height: 22vw; }
      #contents #headline h1 {
        font-size: 1.8rem;
        padding-top: 14vw; } }
  @media screen and (min-width: 62.0625em) {
    #contents #headline h1 {
      padding-top: 10vw; }
      #contents #headline h1 span {
        border: 5px solid #212529;
        padding: 10px 20px; } }

@media screen and (max-width: 62em) {
  #contents #breadcrumb {
    padding-bottom: 40px; } }

#contents h2 {
  font-size: 1.5rem;
  line-height: 1.6;
  font-weight: bold; }
  @media screen and (max-width: 36em) {
    #contents h2 {
      font-size: 1.4rem; } }
  @media screen and (max-width: 30.0625em) {
    #contents h2 {
      font-size: 1.2rem; } }
  #contents h2.lead {
    font-feature-settings: "palt"; }

#contents h3 {
  line-height: 1.5;
  font-weight: bold;
  font-size: 1.375rem; }

#contents p {
  line-height: 1.8; }

#contents dt, #contents dd, #contents th, #contents td {
  line-height: 1.6; }

@media screen and (min-width: 48.0625em) {
  #contents dt {
    font-size: 1.1rem; } }

#contents dd {
  margin-bottom: .4rem; }

#contents li {
  font-size: .9rem;
  line-height: 1.8; }

#contents a {
  text-decoration: underline; }

#contents .color-red {
  color: #E00012; }

#contents .btn a {
  background: #eee;
  display: block;
  text-decoration: none; }
  #contents .btn a:hover, #contents .btn a.hover {
    background: #333;
    color: #fff;
    text-decoration: none; }

@media screen and (max-width: 48em) {
  #contents .viewmore {
    text-align: center; } }

#contents .viewmore a.btn {
  border: 2px solid #333;
  background: #fff;
  color: #333;
  font-weight: bold;
  padding: .7rem 3rem;
  text-decoration: none; }
  #contents .viewmore a.btn:hover, #contents .viewmore a.btn.hover {
    background: #333;
    color: #fff;
    text-decoration: none; }
  @media screen and (max-width: 48em) {
    #contents .viewmore a.btn {
      display: block; } }

.home .nav-sp .logo {
  display: none; }

.home .nav-sp .menu-trigger span {
  background: #fff; }

.home .nav-sp ul li a {
  color: #fff; }

.home .nav-sp .tel a i {
  color: #fff; }

.home #contents-bg {
  background: url("/_uploads/home-bg.jpg") no-repeat center/cover;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0; }

.home #feature img {
  width: 200px;
  margin-bottom: 30px;
  padding: 20px 0; }

.home #feature p {
  font-size: 1.6rem;
  padding-left: 1rem;
  color: #fff; }

.home #main .section p {
  margin-bottom: 0; }

.home #main #menu .menu {
  margin-bottom: 15px;
  font-size: 1.1rem;
  font-weight: bold; }
  .home #main #menu .menu a {
    border: 1px solid #eee;
    padding: 2rem 10px;
    display: block;
    text-decoration: none;
    transition: .2s;
    border: 1px solid #fff;
    color: #fff; }
    .home #main #menu .menu a:hover {
      background: rgba(255, 255, 255, 0.95);
      color: #333; }

@media screen and (min-width: 62.0625em) {
  .home #main #menu {
    display: flex;
    justify-content: space-between; }
    .home #main #menu .menu {
      width: 19%; }
      .home #main #menu .menu a {
        padding: 3rem 10px; } }

.home-movie .movie {
  position: fixed;
  height: 100vh; }
  .home-movie .movie #top-movie {
    overflow: hidden; }

@media screen and (max-width: 62em) {
  .home-movie #contents {
    position: relative; } }

@media screen and (min-width: 62.0625em) {
  .home-movie #contents #main .section p {
    margin-bottom: 0; }
  .home-movie #contents #main #menu .menu {
    margin-bottom: 15px;
    font-size: 1.1rem;
    font-weight: bold;
    z-index: 9; }
    .home-movie #contents #main #menu .menu a {
      border: 1px solid #eee;
      padding: 2rem 10px;
      display: block;
      text-decoration: none;
      transition: .2s;
      border: 1px solid #fff;
      color: #fff; }
      .home-movie #contents #main #menu .menu a:hover {
        background: rgba(255, 255, 255, 0.95);
        color: #333; } }

@media screen and (min-width: 62.0625em) and (min-width: 62.0625em) {
  .home-movie #contents #main #menu {
    display: flex;
    justify-content: space-between; }
    .home-movie #contents #main #menu .menu {
      width: 19%; }
      .home-movie #contents #main #menu .menu a {
        padding: 3rem 10px; } }

.home-movie #contentinfo {
  position: relative; }

.about #main h2 {
  line-height: 1.6; }
  .about #main h2 span {
    font-size: 1.1rem; }

.about #main dd {
  margin-bottom: .8rem; }

.about #main table th, .about #main table td {
  padding: .3rem .5rem .3rem 0; }

.skill #main table th, .skill #main table td {
  padding: .2rem .3rem .2rem 0; }

.recruit #main #interview .voice p.lead {
  line-height: 1.6;
  font-weight: bold; }

.recruit #main #interview .voice .profile {
  border-left: 1px solid #707070;
  padding-left: 10px;
  font-size: .9rem;
  line-height: 1.6; }
  .recruit #main #interview .voice .profile strong {
    font-size: 1rem;
    font-weight: normal; }
    .recruit #main #interview .voice .profile strong span {
      font-size: .8rem; }

.recruit #main #interview .voice h3 {
  font-size: 1.1rem;
  line-height: 1.6; }

.contact form {
  overflow: hidden; }
  .contact form table {
    width: 100%; }
    .contact form table th, .contact form table td {
      display: block;
      width: 100%; }
    .contact form table th span.required {
      color: #F33;
      font-size: .7rem;
      font-weight: normal;
      margin-left: .4rem; }
    .contact form table td {
      margin-bottom: 1rem; }
      .contact form table td input[type="text"], .contact form table td label, .contact form table td textarea {
        box-sizing: border-box;
        width: 100%; }
      .contact form table td label {
        display: block; }
      @media screen and (min-width: 62.0625em) {
        .contact form table td input[type="text"] {
          width: 50%; } }
  @media screen and (max-width: 48em) {
    .contact form .btn {
      width: 100%; } }
  .contact form dl {
    width: 100%; }
    .contact form dl dt {
      font-size: 1rem !important;
      margin-bottom: 2px;
      padding-left: 3px; }
      .contact form dl dt span.required {
        color: #F33;
        font-size: .7rem;
        font-weight: normal;
        margin-left: .4rem; }
    .contact form dl dd {
      margin-bottom: 1rem;
      padding-left: 3px; }
      .contact form dl dd input[type="text"], .contact form dl dd input[type="tel"], .contact form dl dd input[type="email"], .contact form dl dd label, .contact form dl dd textarea {
        box-sizing: border-box;
        width: 100%; }
      .contact form dl dd label {
        display: block;
        margin-bottom: 0; }
      .contact form dl dd .error_blank {
        display: block;
        padding: 3px 0 10px;
        color: #F33;
        font-size: .9rem; }
      @media screen and (min-width: 62.0625em) {
        .contact form dl dd input[type="text"], .contact form dl dd input[type="tel"], .contact form dl dd input[type="email"] {
          width: 50%; } }

.access .map {
  position: relative;
  width: 100%;
  padding-top: 75%; }

.access .map iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important; }
